.navBarContainer {
  font-family: "dosisRegular";
  position: fixed;
  top: 0;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #e7d7c9;
  z-index: 10000;
  display: grid;
  grid-template-columns: 4fr .5fr;
  grid-template-rows: auto;
  grid-template-areas: "navBarTitleContainer navBarToggler" "A A" "B B";
  align-items: center; }
  .navBarContainer .navBarToggler {
    grid-area: navBarToggler;
    cursor: pointer;
    justify-self: flex-end;
    padding-right: 20px; }
    .navBarContainer .navBarToggler .menuIcon {
      font-size: 1.3rem;
      color: #212121; }
  .navBarContainer .linksContainer {
    display: none; }
    .navBarContainer .linksContainer a, .navBarContainer .linksContainer button {
      background-color: #e7d7c9;
      border: 0px;
      color: #212121; }
      .navBarContainer .linksContainer a:hover, .navBarContainer .linksContainer button:hover {
        color: #a38f85; }
  .navBarContainer .linksOpen {
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    align-items: flex-end; }
  .navBarContainer .A {
    grid-area: A;
    justify-self: end; }
  .navBarContainer .B {
    grid-area: B;
    justify-self: end; }
  .navBarContainer .navBarTitleContainer {
    grid-area: navBarTitleContainer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px; }
    .navBarContainer .navBarTitleContainer:hover {
      color: #212121; }
    .navBarContainer .navBarTitleContainer .navBarTitle {
      margin: 0;
      font-family: LoveloBlack;
      font-size: 1rem; }
    .navBarContainer .navBarTitleContainer .navBarSubTitle {
      margin: 0;
      font-family: "dosisRegular";
      font-size: .8rem; }

@media only screen and (min-width: 1024px) {
  .navBarContainer {
    grid-template-areas: "A navBarTitleContainer B";
    grid-template-columns: 1.5fr 1fr 1.5fr;
    justify-items: center;
    padding: 30px 20px; }
    .navBarContainer .navBarToggler {
      display: none; }
    .navBarContainer .linksContainer {
      display: flex;
      align-items: flex-end;
      justify-self: center;
      justify-content: center; }
      .navBarContainer .linksContainer a, .navBarContainer .linksContainer button {
        font-size: 1.2rem; }
        .navBarContainer .linksContainer a:not(:last-child), .navBarContainer .linksContainer button:not(:last-child) {
          padding-right: 10px;
          border-right: 2px solid #212121;
          margin-right: 10px; }
    .navBarContainer .navBarTitleContainer {
      display: flex;
      align-items: center;
      padding: 0px; }
      .navBarContainer .navBarTitleContainer:hover {
        animation-name: scale;
        animation-duration: .5s;
        animation-fill-mode: forwards; }
      .navBarContainer .navBarTitleContainer .navBarTitle {
        font-size: 1.54rem; }
      .navBarContainer .navBarTitleContainer .navBarSubTitle {
        font-size: 1rem; } }

@keyframes scale {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.1); } }

.blur {
  filter: blur(1px); }

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box; }

html p,
html h1,
html h2,
html h3,
html h4 {
  margin: 0;
  font-family: "dosisRegular"; }

html a {
  text-decoration: none;
  color: #212121; }
  html a:hover {
    color: #cdc6c3; }

.App {
  margin-top: 97px; }

.dosisBold {
  font-family: "dosisBold" !important; }

.dosisItalic {
  font-family: "dosisRegular";
  font-style: italic; }

@media only screen and (min-width: 1024px) {
  .App {
    margin-top: 110px; } }

.section1Container {
  background-color: #ede9e3; }
  .section1Container .imageTitleContainer {
    position: relative;
    z-index: 99;
    background-size: cover;
    background-image: url("../src/images/section1Background.jpg");
    height: 170px;
    display: flex; }
  .section1Container .subtitleContainer {
    padding-top: 25px;
    padding-bottom: 25px; }
    .section1Container .subtitleContainer .imageSubtitleContainer {
      display: none; }
  .section1Container .homeDescriptionContainer {
    padding: 0px 20px; }
    .section1Container .homeDescriptionContainer .homeDescriptiontitleDecorative {
      font-family: "beautyDream";
      font-size: 2rem;
      padding-bottom: 10px; }
    .section1Container .homeDescriptionContainer .homeDescriptionTitle {
      font-family: "loveloBlack";
      padding-bottom: 15px;
      padding-top: 5px;
      font-size: 1.2rem; }
    .section1Container .homeDescriptionContainer .homeDescriptionText {
      font-family: "dosisRegular";
      font-size: 0.8rem;
      padding-bottom: 10px;
      text-align: justify; }

@media only screen and (min-width: 768px) {
  .section1Container .imageTitleContainer {
    height: 300px; } }

@media only screen and (min-width: 1024px) {
  .section1Container .imageTitleContainer {
    height: 400px; }
  .section1Container .subtitleContainer {
    display: flex;
    padding-top: 50px;
    padding-bottom: 50px; }
    .section1Container .subtitleContainer .imageSubtitleContainer {
      min-height: 100%;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center; }
      .section1Container .subtitleContainer .imageSubtitleContainer .mapImage {
        opacity: 0.8;
        height: 80%;
        border-radius: 20px; }
      .section1Container .subtitleContainer .imageSubtitleContainer .mapQuote {
        font-family: "dosisRegular";
        font-style: italic;
        padding-top: 5px; }
  .section1Container .homeDescriptionContainer {
    width: 50%;
    padding: 0px 120px 0px 0px; }
    .section1Container .homeDescriptionContainer .homeDescriptiontitleDecorative {
      font-size: 2.5rem;
      padding-bottom: 20px; }
    .section1Container .homeDescriptionContainer .homeDescriptionTitle {
      padding-bottom: 25px;
      padding-top: 10px;
      font-size: 1.56rem; }
    .section1Container .homeDescriptionContainer .homeDescriptionText {
      font-size: 1rem;
      padding-bottom: 20px; } }

.section2Container {
  padding: 10px 20px 15px 20px;
  background-color: #fff; }
  .section2Container .section2TitleContainer {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px; }
    .section2Container .section2TitleContainer .section2Title {
      font-family: "beautyDream";
      font-size: 2rem; }
  .section2Container .section2DescriptionItemsContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, auto);
    grid-template-areas: "A" "C" "E" "B" "D" "F";
    row-gap: 20px;
    padding-top: 15px;
    justify-items: center; }
    .section2Container .section2DescriptionItemsContainer .section2ItemContainer {
      width: 100%;
      align-items: center;
      display: flex; }
      .section2Container .section2DescriptionItemsContainer .section2ItemContainer .section2Item {
        font-size: 1.4rem;
        color: #d4b2a7; }
      .section2Container .section2DescriptionItemsContainer .section2ItemContainer .section2Text {
        margin-left: 10px;
        font-family: "dosisRegular";
        text-align: justify;
        font-size: .8rem; }
    .section2Container .section2DescriptionItemsContainer .A {
      grid-area: A; }
    .section2Container .section2DescriptionItemsContainer .B {
      grid-area: B; }
    .section2Container .section2DescriptionItemsContainer .C {
      grid-area: C; }
    .section2Container .section2DescriptionItemsContainer .D {
      grid-area: D; }
    .section2Container .section2DescriptionItemsContainer .E {
      grid-area: E; }
    .section2Container .section2DescriptionItemsContainer .F {
      grid-area: F; }

@media only screen and (min-width: 1024px) {
  .section2Container {
    padding: 20px 50px 50px 50px; }
    .section2Container .section2TitleContainer {
      padding-top: 30px;
      padding-bottom: 40px; }
      .section2Container .section2TitleContainer .section2Title {
        font-size: 2.5rem; }
    .section2Container .section2DescriptionItemsContainer {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(3, auto);
      grid-template-areas: "A B" "C D" "E F";
      row-gap: 50px;
      padding-top: 25px; }
      .section2Container .section2DescriptionItemsContainer .section2ItemContainer {
        width: 75%;
        align-items: center; }
        .section2Container .section2DescriptionItemsContainer .section2ItemContainer .section2Item {
          font-size: 1.87rem; }
        .section2Container .section2DescriptionItemsContainer .section2ItemContainer .section2Text {
          margin-left: 20px;
          font-size: 1rem; } }

.section3Container {
  position: relative;
  background-color: #ede9e3;
  padding: 10px 0px; }
  .section3Container .section3TitleContainer .section3Title {
    font-family: "loveloBlack";
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 10px; }
  .section3Container .section3TitleContainer .subTitleContainer {
    padding: 0px 20px; }
    .section3Container .section3TitleContainer .subTitleContainer .section3SubTitle {
      font-family: "dosisRegular";
      font-size: 0.8rem;
      text-align: justify;
      margin-bottom: 10px; }
  .section3Container .professionalsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; }
    .section3Container .professionalsContainer .professionalContainer {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
    .section3Container .professionalsContainer .professionalContainer:first-child {
      width: 100%; }
    .section3Container .professionalsContainer .magdaContainer {
      width: 47vw;
      height: calc(47vw / 0.75);
      border-radius: 10px;
      background-image: url("../src/images/magda.jpeg");
      background-size: contain;
      opacity: 0.5; }
      .section3Container .professionalsContainer .magdaContainer:hover {
        opacity: 1; }
    .section3Container .professionalsContainer .jorgeContainer {
      width: 47vw;
      height: calc(47vw / 0.75);
      background-image: url("../src/images/jorge.jpeg");
      border-radius: 10px;
      background-size: contain;
      opacity: 0.5; }
      .section3Container .professionalsContainer .jorgeContainer:hover {
        opacity: 1; }
    .section3Container .professionalsContainer .matiasContainer {
      width: 47vw;
      height: calc(47vw / 0.75);
      background-image: url("../src/images/matias.jpg");
      border-radius: 10px;
      background-size: contain;
      opacity: 0.5; }
      .section3Container .professionalsContainer .matiasContainer:hover {
        opacity: 1; }
    .section3Container .professionalsContainer .professionalDescriptionContainer {
      margin: 10px;
      background-color: rgba(163, 143, 133, 0.5);
      border-radius: 10px;
      padding-left: 5px;
      padding-right: 5px;
      padding-bottom: 5px;
      margin-bottom: 50px; }
      .section3Container .professionalsContainer .professionalDescriptionContainer .professionalName {
        font-family: "loveloBlack";
        padding: 5px;
        font-size: 0.8rem;
        color: #fff;
        text-align: center; }
      .section3Container .professionalsContainer .professionalDescriptionContainer .professionalDescription {
        font-family: "dosisSemiBold";
        padding-bottom: 2px;
        font-size: 0.7rem;
        color: #ede9e3;
        text-align: center; }
      .section3Container .professionalsContainer .professionalDescriptionContainer .seeMore {
        cursor: pointer; }
        .section3Container .professionalsContainer .professionalDescriptionContainer .seeMore:hover {
          color: #fff; }
  .section3Container .quoteContainer {
    text-align: center;
    margin-top: 10px;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 0.8rem; }
  .section3Container .videoContainer {
    display: flex;
    justify-content: center; }
    .section3Container .videoContainer video {
      width: 80%;
      height: 50vw;
      border-radius: 20px;
      object-fit: fill; }

@media only screen and (min-width: 768px) {
  .section3Container .videoContainer video {
    width: 320px;
    height: 200px; } }

@media only screen and (min-width: 1024px) {
  .section3Container {
    padding: 30px 50px 50px 50px; }
    .section3Container .section3TitleContainer {
      margin: 40px 100px 60px 100px; }
      .section3Container .section3TitleContainer .section3Title {
        font-size: 1.37rem;
        margin-bottom: 25px; }
      .section3Container .section3TitleContainer .subTitleContainer {
        padding: 0px 70px; }
        .section3Container .section3TitleContainer .subTitleContainer .section3SubTitle {
          font-size: 1rem; }
    .section3Container .professionalsContainer {
      flex-direction: row; }
      .section3Container .professionalsContainer .professionalContainer {
        flex-direction: row; }
      .section3Container .professionalsContainer .professionalContainer:first-child {
        margin-bottom: 200px; }
      .section3Container .professionalsContainer .magdaContainer {
        width: 27rem;
        height: 36rem; }
      .section3Container .professionalsContainer .jorgeContainer {
        width: 27rem;
        height: 36rem; }
      .section3Container .professionalsContainer .matiasContainer {
        width: 27rem;
        height: 36rem; }
      .section3Container .professionalsContainer .professionalDescriptionContainer {
        margin: 0;
        position: absolute;
        bottom: -150px;
        padding-left: 10px;
        padding-right: 10px; }
        .section3Container .professionalsContainer .professionalDescriptionContainer .professionalName {
          padding: 5px 15px;
          font-size: 1.18rem; }
        .section3Container .professionalsContainer .professionalDescriptionContainer .professionalDescription {
          padding-bottom: 5px;
          font-size: 1rem; }
    .section3Container .quoteContainer {
      margin-top: 50px;
      font-size: 1rem; }
    .section3Container .videoContainer {
      padding-top: 200px; }
      .section3Container .videoContainer video {
        width: 400px;
        height: 250px; } }

.footerContainer {
  position: relative; }
  .footerContainer .footerTextContainer {
    display: flex;
    flex-direction: column;
    background-color: #e7d7c9;
    padding: 10px 20px;
    justify-content: space-evenly;
    align-items: center;
    z-index: 100; }
    .footerContainer .footerTextContainer .footerText {
      font-family: "dosisRegular";
      font-size: 0.9rem;
      text-align: center; }
  .footerContainer .footerButtonContainer {
    background-color: #a38f85;
    padding: 8px 25px;
    border-radius: 5px;
    margin-top: 7px; }
    .footerContainer .footerButtonContainer:hover {
      background-color: #d4b2a7;
      cursor: pointer; }
    .footerContainer .footerButtonContainer .footerButtonText {
      font-family: "dosisRegular";
      color: #fff;
      font-size: 0.9rem; }
  .footerContainer .footerCopyrightContainer {
    display: flex;
    color: #212121;
    background-color: #e7d7c9;
    justify-content: center;
    align-items: center;
    padding: 0 10px 10px 10px; }
    .footerContainer .footerCopyrightContainer h4 {
      font-family: "dosisRegular";
      font-size: 0.7rem; }

@media only screen and (min-width: 1024px) {
  .footerContainer .footerTextContainer {
    flex-direction: row;
    padding: 20px 0px; }
    .footerContainer .footerTextContainer .footerText {
      font-size: 1.25rem; }
  .footerContainer .footerButtonContainer {
    padding: 10px 50px;
    margin-top: 10px;
    margin-bottom: 10px; }
    .footerContainer .footerButtonContainer .footerButtonText {
      font-size: 1.12rem; }
  .footerContainer .footerCopyrightContainer {
    padding: 10px; }
    .footerContainer .footerCopyrightContainer h4 {
      padding-left: 5px;
      font-size: 0.87rem; } }

.frequentQuestionsContainer {
  padding-top: 20px;
  position: relative; }
  .frequentQuestionsContainer .imgBackground {
    display: none; }
  .frequentQuestionsContainer .frequentQuestionsTitle {
    z-index: 2;
    text-align: center;
    font-family: "LoveloBlack";
    padding-bottom: 10px;
    font-size: 1rem; }
  .frequentQuestionsContainer .questionContainer {
    margin-top: 15px; }
    .frequentQuestionsContainer .questionContainer .questionText {
      position: relative;
      z-index: 10;
      font-family: "dosisRegular";
      font-size: .9rem; }
      .frequentQuestionsContainer .questionContainer .questionText:hover {
        color: #a38f85; }
  .frequentQuestionsContainer .answerContainer {
    position: relative;
    z-index: 200;
    background-color: #fff;
    border-top: 1px solid #e7d7c9;
    border-bottom: 1px solid #e7d7c9;
    margin: 5px 0px;
    padding: 5px 5px; }
    .frequentQuestionsContainer .answerContainer .answerText {
      font-family: "dosisRegular";
      text-align: justify;
      font-size: .8rem; }
  .frequentQuestionsContainer .frequentFootContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 30px 10px 5px; }
    .frequentQuestionsContainer .frequentFootContainer .frequentFootText {
      font-family: "dosisRegular";
      padding-bottom: 5px;
      font-size: .8rem; }

@media only screen and (min-width: 768px) {
  .frequentQuestionsContainer {
    padding: 30px 20px; } }

@media only screen and (min-width: 1024px) {
  .frequentQuestionsContainer {
    padding: 50px; }
    .frequentQuestionsContainer .imgBackground {
      display: block;
      height: 1000px;
      max-height: 100%;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 55%; }
    .frequentQuestionsContainer .frequentQuestionsTitle {
      padding-bottom: 20px;
      font-size: 1.25rem; }
    .frequentQuestionsContainer .questionContainer {
      margin: 10px; }
      .frequentQuestionsContainer .questionContainer .questionText {
        font-size: 1.12rem; }
    .frequentQuestionsContainer .answerContainer {
      margin: 10px 0px;
      padding: 10px 30px; }
      .frequentQuestionsContainer .answerContainer .answerText {
        font-size: 1rem; }
    .frequentQuestionsContainer .frequentFootContainer {
      padding: 40px 70px 10px; }
      .frequentQuestionsContainer .frequentFootContainer .frequentFootText {
        padding-bottom: 5px;
        width: 60%;
        font-size: 1rem; } }

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #a38f85;
  z-index: 10001;
  animation: slideInFromRight 0.5s ease-in; }
  .sidebar .closeBtn {
    color: #fff;
    position: absolute;
    font-family: "LoveloBlack";
    cursor: pointer;
    z-index: 200;
    right: 15px;
    top: 10px;
    font-size: 1.4rem; }
  .sidebar .sidebarContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.8fr 2fr;
    align-items: center;
    height: 100%;
    row-gap: 0px; }
    .sidebar .sidebarContainer .sidebarTitleContainer {
      height: 100%;
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .sidebar .sidebarContainer .sidebarTitleContainer:before {
        content: "";
        background-image: url("../src/images/sidebarTitleBackground.jpg");
        background-size: cover;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0.4; }
      .sidebar .sidebarContainer .sidebarTitleContainer .sidebarTitle {
        color: #fff;
        font-family: "loveloBlack";
        position: relative;
        font-size: 1.8rem; }
      .sidebar .sidebarContainer .sidebarTitleContainer .sidebarSubTitle {
        position: relative;
        color: #fff;
        font-family: "dosisRegular";
        font-size: 1rem; }
    .sidebar .sidebarContainer .sidebarFormContainer {
      padding-left: 2%;
      padding-right: 2%; }
      .sidebar .sidebarContainer .sidebarFormContainer form {
        display: flex;
        flex-direction: column; }
        .sidebar .sidebarContainer .sidebarFormContainer form .nameSurnameContainer {
          display: flex;
          justify-content: space-between; }
        .sidebar .sidebarContainer .sidebarFormContainer form input {
          padding: 2px 10px;
          margin-bottom: 10px;
          font-family: "dosisRegular";
          font-size: 0.8rem; }
        .sidebar .sidebarContainer .sidebarFormContainer form textarea {
          resize: none;
          font-family: "dosisRegular";
          height: 70px;
          padding: 5px 10px;
          margin-bottom: 10px;
          font-size: 0.8rem; }
        .sidebar .sidebarContainer .sidebarFormContainer form select {
          padding: 5px 10px;
          font-family: "dosisRegular";
          margin-bottom: 10px;
          font-size: 0.8rem; }
        .sidebar .sidebarContainer .sidebarFormContainer form .buttonSubmit {
          background-color: #d4b2a7;
          color: #fff;
          border-color: #a38f85;
          font-family: "dosisMedium";
          border-radius: 10px;
          margin-top: 10px;
          padding: 8px 0px;
          font-size: 0.9rem; }
          .sidebar .sidebarContainer .sidebarFormContainer form .buttonSubmit:hover {
            background-color: #e7d7c9;
            color: #212121; }
      .sidebar .sidebarContainer .sidebarFormContainer .sidebarDescription {
        text-align: center;
        color: #fff;
        font-family: "dosisRegular";
        padding-top: 10px;
        font-size: 0.8rem; }

@media only screen and (min-width: 768px) {
  .sidebar {
    width: 55%;
    height: 70%; }
    .sidebar .closeBtn {
      right: 20px;
      top: 10px;
      font-size: 1.4rem; }
    .sidebar .sidebarContainer {
      grid-template-rows: 1fr 2fr; } }

@media only screen and (min-width: 1024px) {
  .sidebar {
    width: 38%;
    height: 100%; }
    .sidebar .closeBtn {
      right: 30px;
      top: 15px;
      font-size: 1.55rem; }
    .sidebar .sidebarContainer {
      grid-template-rows: 1fr 2fr;
      row-gap: 40px; }
      .sidebar .sidebarContainer .sidebarTitleContainer .sidebarTitle {
        font-size: 2.18rem; }
      .sidebar .sidebarContainer .sidebarTitleContainer .sidebarSubTitle {
        font-size: 1.25rem; }
      .sidebar .sidebarContainer .sidebarFormContainer {
        padding-left: 10%;
        padding-right: 10%; }
        .sidebar .sidebarContainer .sidebarFormContainer form input {
          font-size: 0.93rem; }
        .sidebar .sidebarContainer .sidebarFormContainer form textarea {
          height: 75px;
          padding: 5px 10px;
          margin-bottom: 10px;
          font-size: 0.93rem; }
        .sidebar .sidebarContainer .sidebarFormContainer form select {
          font-size: 0.93rem; }
        .sidebar .sidebarContainer .sidebarFormContainer form .buttonSubmit {
          margin-top: 20px;
          padding: 10px 5px;
          font-size: 1rem; }
        .sidebar .sidebarContainer .sidebarFormContainer .sidebarDescription {
          padding-top: 15px;
          font-size: 1rem; } }

.sidebarClosed {
  animation: slideOutToRight 0.5s ease-out; }

.loadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; }
  .loadingContainer .brain-icon {
    animation: rotate 1s linear infinite; }
  .loadingContainer .loadingText {
    font-family: "dosisRegular";
    font-size: 1.56rem; }

@keyframes rotate {
  0% {
    transform: rotate(0deg);
    animation-timing-function: ease-in; }
  75% {
    transform: rotate(360deg);
    animation-timing-function: ease-out; }
  100% {
    transform: rotate(360deg); } }

.react-datetime-picker {
  background-color: #fff;
  margin-bottom: 10px;
  width: 100%; }
  .react-datetime-picker input {
    width: fit-content; }

.loadingContainer {
  flex: 1;
  min-height: 90vh; }

.blogContainer .title {
  font-family: "beautyDream";
  text-align: center;
  color: #212121;
  padding-top: 20px;
  font-size: 2rem; }

.blogContainer .blogGrid {
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  row-gap: 20px;
  padding-top: 20px;
  padding-bottom: 20px; }
  .blogContainer .blogGrid .articleContainer {
    position: relative;
    background-color: #a38f85;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 96%;
    height: calc(96vw / 2); }
    .blogContainer .blogGrid .articleContainer:before {
      background-image: var(--image-url); }
    .blogContainer .blogGrid .articleContainer:before {
      border-radius: 10px;
      content: "";
      background-size: cover;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.7; }
    .blogContainer .blogGrid .articleContainer:hover:before {
      opacity: 1;
      cursor: pointer; }
    .blogContainer .blogGrid .articleContainer h2 {
      color: #fff;
      font-family: "dosisMedium";
      position: relative;
      font-size: 1.2rem;
      text-align: center; }
      .blogContainer .blogGrid .articleContainer h2:hover {
        cursor: pointer; }

.blogContainer .introductoryArticlesGrid {
  padding-bottom: 0px;
  margin-bottom: 0px; }

@media only screen and (min-width: 1024px) {
  .blogContainer .title {
    padding-top: 50px;
    font-size: 2.5rem; }
  .blogContainer .blogGrid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4, auto);
    row-gap: 50px;
    padding-top: 75px;
    padding-bottom: 75px; }
    .blogContainer .blogGrid .articleContainer {
      height: 275px;
      width: 550px; }
      .blogContainer .blogGrid .articleContainer h2 {
        font-size: 1.75rem; }
  .blogContainer .introductoryArticlesGrid {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 0;
    border-bottom: 1px solid #e7d7c9; }
    .blogContainer .introductoryArticlesGrid .articleContainer {
      height: 180px;
      width: 300px; } }

.articleTextContainer {
  position: relative;
  background-color: #a38f85;
  display: flex;
  flex-direction: column;
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  border-radius: 20px;
  margin: 4px;
  padding: 10px 15px; }
  .articleTextContainer:before {
    background-image: var(--image-url); }
  .articleTextContainer .closeBtn {
    color: #fff;
    position: absolute;
    right: 10px;
    top: 5px;
    font-family: "LoveloBlack";
    cursor: pointer;
    z-index: 200;
    font-size: 1rem; }
  .articleTextContainer:before {
    border-radius: 20px;
    content: "";
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.3; }
  .articleTextContainer .parragraph {
    padding: 3px 0px; }
  .articleTextContainer h2 {
    text-align: center;
    font-family: "dosisSemiBold";
    color: #e7d7c9;
    padding-bottom: 10px;
    font-size: 1.2rem; }
  .articleTextContainer p {
    margin: 0;
    padding: 0;
    text-align: justify;
    color: .articleTextContainer p light;
    position: relative;
    color: #fff;
    padding: 5px 0px;
    font-size: 0.8rem; }
  .articleTextContainer .textFoot {
    color: #fff;
    text-align: end;
    padding-bottom: 0; }

@media only screen and (min-width: 1024px) {
  .articleTextContainer {
    margin: 75px;
    padding: 30px 75px; }
    .articleTextContainer .closeBtn {
      right: 30px;
      top: 15px;
      font-size: 1.5rem; }
    .articleTextContainer .parragraph {
      padding: 10px 0px; }
    .articleTextContainer p {
      font-size: 1.12rem;
      padding: 10px 0px; }
    .articleTextContainer h2 {
      padding-bottom: 30px;
      font-size: 2rem; } }

.closed {
  animation: fadeOut 1s ease-in-out forwards; }

.seeMoreContainerWrapper {
  position: absolute;
  z-index: 2000;
  top: 45px;
  left: 2%;
  width: 96%;
  border-radius: 20px;
  box-shadow: 0px 0px 13px 8px rgba(163, 143, 133, 0.35);
  -webkit-box-shadow: 0px 0px 13px 8px rgba(163, 143, 133, 0.35);
  -moz-box-shadow: 0px 0px 13px 8px rgba(163, 143, 133, 0.35);
  animation: fadeIn 1s ease-in-out forwards;
  overflow: hidden; }
  .seeMoreContainerWrapper .seeMoreContainer {
    background-color: #ede9e3;
    border-radius: 20px;
    opacity: 100;
    padding: 10px;
    overflow-y: auto;
    max-height: calc(var(--section3Height) - 100px);
    -webkit-overflow-scrolling: touch; }
    .seeMoreContainerWrapper .seeMoreContainer::-webkit-scrollbar {
      width: 5px; }
    .seeMoreContainerWrapper .seeMoreContainer::-webkit-scrollbar-track {
      background-color: #ede9e3; }
    .seeMoreContainerWrapper .seeMoreContainer::-webkit-scrollbar-thumb {
      background-color: #d4b2a7;
      border-radius: 100px; }
    .seeMoreContainerWrapper .seeMoreContainer .closeBtn {
      color: #a38f85;
      position: absolute;
      font-family: "LoveloBlack";
      cursor: pointer;
      z-index: 200;
      right: 10px;
      top: 10px;
      font-size: 1.1rem; }
    .seeMoreContainerWrapper .seeMoreContainer h2 {
      text-align: center;
      font-family: "loveloBlack";
      color: #a38f85;
      padding-top: 5px;
      font-size: 1.3rem; }
    .seeMoreContainerWrapper .seeMoreContainer h3 {
      text-align: center;
      font-family: "dosisRegular";
      color: #a38f85;
      padding: 10px 50px;
      font-size: 1rem; }
    .seeMoreContainerWrapper .seeMoreContainer .teamDescriptionContainer {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(14, align-items);
      row-gap: 10px;
      padding: 10px 10px 10px 0px;
      align-items: center; }
      .seeMoreContainerWrapper .seeMoreContainer .teamDescriptionContainer .teamDescriptionItemContainer {
        display: flex;
        align-items: center; }
        .seeMoreContainerWrapper .seeMoreContainer .teamDescriptionContainer .teamDescriptionItemContainer .teamDescriptionItem {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start; }
      .seeMoreContainerWrapper .seeMoreContainer .teamDescriptionContainer .teamDescriptionText {
        padding-left: 10px;
        color: #212121;
        font-family: "dosisRegular";
        text-align: justify;
        font-size: 0.8rem; }

@media only screen and (min-width: 1024px) {
  .seeMoreContainerWrapper {
    top: 120px;
    left: 13%;
    width: 74%; }
    .seeMoreContainerWrapper .seeMoreContainer .closeBtn {
      right: 25px;
      top: 15px;
      font-size: 1.5rem; }
    .seeMoreContainerWrapper .seeMoreContainer h2 {
      padding-top: 30px;
      font-size: 1.87rem; }
    .seeMoreContainerWrapper .seeMoreContainer h3 {
      padding: 30px 120px;
      font-size: 1.5rem; }
    .seeMoreContainerWrapper .seeMoreContainer .teamDescriptionContainer {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      row-gap: 30px;
      column-gap: 100px;
      padding: 20px 50px;
      grid-auto-flow: dense; }
      .seeMoreContainerWrapper .seeMoreContainer .teamDescriptionContainer .leftColumn {
        grid-column: 1; }
      .seeMoreContainerWrapper .seeMoreContainer .teamDescriptionContainer .rightColumn {
        grid-column: 2; }
      .seeMoreContainerWrapper .seeMoreContainer .teamDescriptionContainer .teamDescriptionText {
        font-size: 1rem; } }

.seeMoreClosed {
  animation: fadeOut 1s ease-in-out backwards; }

.conventionsContainer {
  position: fixed;
  z-index: 5000;
  top: 150px;
  left: 2%;
  width: 96%;
  background-color: #ede9e3;
  border-radius: 20px;
  box-shadow: 0px 0px 13px 8px rgba(163, 143, 133, 0.35);
  -webkit-box-shadow: 0px 0px 13px 8px rgba(163, 143, 133, 0.35);
  -moz-box-shadow: 0px 0px 13px 8px rgba(163, 143, 133, 0.35);
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards; }
  .conventionsContainer .closeBtn {
    color: #a38f85;
    position: absolute;
    right: 10px;
    top: 5px;
    font-family: "LoveloBlack";
    cursor: pointer;
    font-size: 1rem;
    z-index: 200; }
  .conventionsContainer h2 {
    text-align: center;
    font-family: "loveloBlack";
    color: #a38f85;
    padding-top: 10px;
    font-size: 1.2rem; }
  .conventionsContainer h3 {
    text-align: center;
    font-family: "dosisRegular";
    color: #a38f85;
    padding: 10px 0px;
    font-size: 1rem; }
  .conventionsContainer .conventionsDescriptionContainer {
    padding: 5px 10px; }
    .conventionsContainer .conventionsDescriptionContainer .itemContainer {
      display: flex;
      margin-bottom: 10px; }
      .conventionsContainer .conventionsDescriptionContainer .itemContainer .icon path {
        stroke: #a38f85; }
      .conventionsContainer .conventionsDescriptionContainer .itemContainer p {
        font-family: "dosisRegular";
        margin-left: 10px;
        font-size: .8rem; }
    .conventionsContainer .conventionsDescriptionContainer .isapresContainer {
      padding-top: 20px;
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-evenly; }
      .conventionsContainer .conventionsDescriptionContainer .isapresContainer img {
        margin: 10px 5px;
        max-width: 100px;
        transition: transform .5s; }
        .conventionsContainer .conventionsDescriptionContainer .isapresContainer img:hover {
          transform: scale(1.2); }

@media only screen and (min-width: 1024px) {
  .conventionsContainer {
    top: 150px;
    left: 12%;
    width: 76%;
    padding: 10px;
    padding-bottom: 20px; }
    .conventionsContainer .closeBtn {
      right: 30px;
      top: 15px;
      font-size: 1.5rem; }
    .conventionsContainer h2 {
      padding-top: 30px;
      font-size: 1.87rem; }
    .conventionsContainer h3 {
      padding: 25px 0px;
      font-size: 1.5rem; }
    .conventionsContainer .conventionsDescriptionContainer {
      padding: 10px 50px; }
      .conventionsContainer .conventionsDescriptionContainer .itemContainer {
        margin: 0px 20px 40px 20px; }
        .conventionsContainer .conventionsDescriptionContainer .itemContainer p {
          margin-left: 20px;
          font-size: 1.2rem; }
      .conventionsContainer .conventionsDescriptionContainer .isapresContainer {
        padding-top: 0px; }
        .conventionsContainer .conventionsDescriptionContainer .isapresContainer img {
          margin-bottom: 0px; } }

.conventionsContainerClosed {
  animation: fadeOut 1s ease-in-out backwards; }

.adminContainer {
  width: 100%; }
  .adminContainer .formContainer {
    margin: 100px 150px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .adminContainer .formContainer .saveButton {
      background-color: #0be20b;
      border-radius: 10px;
      padding: 10px 15px; }
    .adminContainer .formContainer .resetButton {
      background-color: #fb995c;
      border: 2px solid #000;
      border-radius: 10px;
      padding: 10px 15px;
      cursor: pointer; }
    .adminContainer .formContainer .deleteButton {
      background-color: red;
      border: 2px solid #000;
      border-radius: 10px;
      padding: 10px 15px;
      margin-top: 10px;
      cursor: pointer; }
    .adminContainer .formContainer .inputRow {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px; }
    .adminContainer .formContainer label {
      margin-right: 10px; }
    .adminContainer .formContainer input {
      flex: 1;
      height: 50px;
      padding-left: 25px;
      padding-right: 25px;
      margin: 10px; }
    .adminContainer .formContainer input[type="file"]::-webkit-file-upload-button {
      border-radius: 10px;
      padding: 5px 7px; }
    .adminContainer .formContainer textarea {
      flex: 1;
      padding: 20px;
      min-height: 200px; }
  .adminContainer .blogGrid {
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4, auto);
    row-gap: 50px;
    padding-top: 75px;
    padding-bottom: 75px; }
    .adminContainer .blogGrid .articleContainer {
      position: relative;
      background-color: #a38f85;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      height: 275px;
      width: 550px; }
      .adminContainer .blogGrid .articleContainer:before {
        background-image: var(--image-url); }
      .adminContainer .blogGrid .articleContainer:before {
        border-radius: 10px;
        content: "";
        background-size: cover;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0.7; }
      .adminContainer .blogGrid .articleContainer:hover:before {
        opacity: 1;
        cursor: pointer; }
      .adminContainer .blogGrid .articleContainer h2 {
        color: #fff;
        font-family: "dosisMedium";
        position: relative;
        font-size: 1.75rem;
        text-align: center; }
        .adminContainer .blogGrid .articleContainer h2:hover {
          cursor: pointer; }

.loginFormContainer {
  width: 100%;
  height: 60vh;
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center; }
  .loginFormContainer .formRow {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50vh; }
    .loginFormContainer .formRow label {
      justify-self: start;
      padding-right: 20px; }
  .loginFormContainer .loginButton {
    border-radius: 10px;
    padding: 5px 30px; }

.loadingContainer {
  flex: 1;
  min-height: 90vh; }

.calendarContainer {
  min-height: 90vh;
  position: relative;
  padding-bottom: 2rem; }
  .calendarContainer .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; }
    .calendarContainer .modal-overlay .modal-content {
      background: #ede9e3;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      text-align: center;
      max-width: 400px;
      width: 100%; }
      .calendarContainer .modal-overlay .modal-content .modal-title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px; }
      .calendarContainer .modal-overlay .modal-content .modal-description {
        font-size: 16px;
        margin-bottom: 20px; }
      .calendarContainer .modal-overlay .modal-content .button-container {
        display: flex;
        justify-content: center; }
        .calendarContainer .modal-overlay .modal-content .button-container .whatsapp-button {
          border-radius: 1rem;
          padding: .6rem 1rem;
          background-color: #fff;
          color: #212121;
          border: 2px solid #a38f85; }
        .calendarContainer .modal-overlay .modal-content .button-container .whatsapp-button:hover {
          background-color: #a38f85; }
  .calendarContainer .title {
    z-index: 2;
    text-align: center;
    font-family: "LoveloBlack";
    padding-top: 3rem;
    padding-bottom: 10px;
    font-size: 1rem; }
  .calendarContainer .dateSelectorContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px; }
    .calendarContainer .dateSelectorContainer .react-calendar__tile--now {
      background-color: #e7d7c9; }
    .calendarContainer .dateSelectorContainer .react-calendar__tile--now:hover {
      background-color: #ede9e3; }
    .calendarContainer .dateSelectorContainer .event-day {
      position: relative; }
    .calendarContainer .dateSelectorContainer .event-day::after {
      content: '';
      position: absolute;
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
      width: 6px;
      height: 6px;
      background-color: #cdc6c3;
      border-radius: 50%; }
    .calendarContainer .dateSelectorContainer .dateSelectorTitle {
      z-index: 2;
      text-align: center;
      font-family: "LoveloBlack";
      padding-top: 20px;
      padding-bottom: 10px;
      font-size: 1rem; }
    .calendarContainer .dateSelectorContainer .timesAvailableContainer .timesAvailableTitle {
      z-index: 2;
      font-family: "LoveloBlack";
      font-size: .8rem;
      padding-bottom: 1rem; }
    .calendarContainer .dateSelectorContainer .timesAvailableContainer .timesItemsContainer {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      gap: 10px; }
      .calendarContainer .dateSelectorContainer .timesAvailableContainer .timesItemsContainer .noAppointments {
        padding-top: 1rem; }
      .calendarContainer .dateSelectorContainer .timesAvailableContainer .timesItemsContainer .timeItem {
        border: 1px solid #aaa;
        flex: 0 0 calc(33% - 10px);
        box-sizing: border-box;
        text-align: center;
        padding: 10px;
        background-color: white;
        cursor: pointer; }
      .calendarContainer .dateSelectorContainer .timesAvailableContainer .timesItemsContainer .timeItem:hover {
        border: 1px solid black;
        background-color: #f4f4f4; }
      .calendarContainer .dateSelectorContainer .timesAvailableContainer .timesItemsContainer .selected {
        background-color: #006edc;
        color: white; }
      .calendarContainer .dateSelectorContainer .timesAvailableContainer .timesItemsContainer .selected:hover {
        background-color: #006edc;
        color: white; }
    .calendarContainer .dateSelectorContainer .detailsContainer .inputContainer {
      display: flex;
      flex-direction: column; }
      .calendarContainer .dateSelectorContainer .detailsContainer .inputContainer input {
        margin-top: .2rem;
        padding: .1rem .4rem;
        border: 1px solid #a38f85;
        border-radius: .4rem; }
  .calendarContainer .button {
    margin-top: 1rem;
    border-radius: 1rem;
    padding: .5rem 1rem;
    background-color: #d4b2a7;
    color: white; }
  .calendarContainer .button:hover {
    background-color: #a38f85; }

@media only screen and (min-width: 768px) {
  .calendarContainer .dateSelectorContainer .timesAvailableContainer {
    width: 15rem; }
    .calendarContainer .dateSelectorContainer .timesAvailableContainer .timesAvailableTitle {
      font-size: .9rem; }
    .calendarContainer .dateSelectorContainer .timesAvailableContainer .timesItemsContainer .timeItem {
      flex: 0 0 calc(50% - 10px); } }

@media only screen and (min-width: 1024px) {
  .calendarContainer {
    margin-top: 2rem; }
    .calendarContainer .dateSelectorContainer {
      margin-top: 2rem;
      padding: 2rem;
      gap: 20px;
      flex-direction: row; }
      .calendarContainer .dateSelectorContainer .timesAvailableContainer {
        text-align: center;
        padding-top: 20px;
        padding-bottom: 10px;
        font-size: 1rem;
        width: 20rem; } }

@media only screen and (min-width: 1300px) {
  .calendarContainer .dateSelectorContainer {
    gap: 50px; }
    .calendarContainer .dateSelectorContainer .timesAvailableContainer {
      width: 25rem; } }

.main-content {
  filter: blur(2px); }

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.3); }
  100% {
    opacity: 1;
    transform: scale(1); } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(0.3); } }

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes slideOutToRight {
  0% {
    transform: translateX(0);
    opacity: 1; }
  100% {
    transform: translateX(100%);
    opacity: 0; } }
